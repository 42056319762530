var refs = 0;
var dispose;
var content = require("!!../../../node_modules/css-loader/index.js??ref--6-1!../../../node_modules/less-loader/dist/cjs.js??ref--6-2!./basic.less");

if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) exports.locals = content.locals;

exports.use = exports.ref = function() {
	if(!(refs++)) {
		dispose = require("!../../../node_modules/style-loader/lib/addStyles.js")(content, {"hmr":false});
	}

	return exports;
};

exports.unuse = exports.unref = function() {
  if(refs > 0 && !(--refs)) {
	   dispose();
		 dispose = null;
  }
};
