export default function parseJSON(value, defaultValue = null) {
  let res = defaultValue;
  try {
    res = JSON.parse(value);
  } catch {
    // 
  }

  return res;
}
