import cx from 'classnames';

import styles from './style.less';
import useStyles from '@/hooks/useStyles';

function BasicLayout ({ className, children, ...nextProps }) {
  useStyles(styles);

  return (
    <div
      {...nextProps}
      className={cx(styles.locals.basic, className)}
    >
      {children}
    </div>
  );
}

export default BasicLayout;
