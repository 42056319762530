import { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import Spinner from '@/components/widgets/Spinner/';
import AdminLayout from '@/components/layouts/AdminLayout';


const DashboardHomePage = lazy(() => import(/* webpackChunkName: "pages/dashboard/home" */ '@/components/pages/DashboardHomePage/'));
const DashboardAnalysisPage = lazy(() => import(/* webpackChunkName: "pages/dashboard/analysis" */ '@/components/pages/DashboardAnalysisPage/'));

// 用户管理
const UserListPage = lazy(() => import(/* webpackChunkName: "pages/user/users" */ '@/components/pages/UserListPage/'));
const UserViewPage = lazy(() => import(/* webpackChunkName: "pages/user/user" */ '@/components/pages/UserViewPage/'));
const UserPhoneListPage = lazy(() => import(/* webpackChunkName: "pages/user/phones" */ '@/components/pages/UserPhoneListPage/'));
const UserInboxListPage = lazy(() => import(/* webpackChunkName: "pages/user/inboxes" */ '@/components/pages/UserInboxListPage/'));
const UserLogListPage = lazy(() => import(/* webpackChunkName: "pages/user/logs" */ '@/components/pages/UserLogListPage/'));
const UserLogViewPage = lazy(() => import(/* webpackChunkName: "pages/user/log" */ '@/components/pages/UserLogViewPage/'));
const VipOrderListPage = lazy(() => import(/* webpackChunkName: "pages/vip/orders" */ '@/components/pages/VipOrderListPage/'));
const VipOrderViewPage = lazy(() => import(/* webpackChunkName: "pages/vip/order" */ '@/components/pages/VipOrderViewPage/'));
const VipCardListPage = lazy(() => import(/* webpackChunkName: "pages/vip/cards" */ '@/components/pages/VipCardListPage/'));
const VipCardViewPage = lazy(() => import(/* webpackChunkName: "pages/vip/card" */ '@/components/pages/VipCardViewPage/'));
const VipCouponListPage = lazy(() => import(/* webpackChunkName: "pages/vip/coupons" */ '@/components/pages/VipCouponListPage/'));
const VipCouponViewPage = lazy(() => import(/* webpackChunkName: "pages/vip/coupon" */ '@/components/pages/VipCouponViewPage/'));
const UserMessageListPage = lazy(() => import(/* webpackChunkName: "pages/user/messages" */ '@/components/pages/UserMessageListPage/'));
const UserMessageViewPage = lazy(() => import(/* webpackChunkName: "pages/user/message" */ '@/components/pages/UserMessageViewPage/'));

// 快递员管理
const CourierListPage = lazy(() => import(/* webpackChunkName: "pages/courier/users" */ '@/components/pages/CourierListPage/'));
const CourierViewPage = lazy(() => import(/* webpackChunkName: "pages/courier/user" */ '@/components/pages/CourierViewPage/'));
const BalanceListPage = lazy(() => import(/* webpackChunkName: "pages/courier/balances" */ '@/components/pages/BalanceListPage/'));
const BalanceViewPage = lazy(() => import(/* webpackChunkName: "pages/courier/balance" */ '@/components/pages/BalanceViewPage/'));
const WithdrawListPage = lazy(() => import(/* webpackChunkName: "pages/courier/balances" */ '@/components/pages/WithdrawListPage'));
const WithdrawViewPage = lazy(() => import(/* webpackChunkName: "pages/courier/balance" */ '@/components/pages/WithdrawViewPage/'));
const IdentityListPage = lazy(() => import(/* webpackChunkName: "pages/courier/identities" */ '@/components/pages/IdentityListPage/'));
const IdentityViewPage = lazy(() => import(/* webpackChunkName: "pages/courier/identity" */ '@/components/pages/IdentityViewPage/'));

const PackageListPage = lazy(() => import(/* webpackChunkName: "pages/package/packages" */ '@/components/pages/PackageListPage/'));
const PackageViewPage = lazy(() => import(/* webpackChunkName: "pages/package/package" */ '@/components/pages/PackageViewPage/'));

const PostListPage = lazy(() => import(/* webpackChunkName: "pages/post/posts" */ '@/components/pages/PostListPage/'));
const PostViewPage = lazy(() => import(/* webpackChunkName: "pages/post/post" */ '@/components/pages/PostViewPage/'));

const ShopListPage = lazy(() => import(/* webpackChunkName: "pages/shop/shops" */ '@/components/pages/ShopListPage/'));
const ShopViewPage = lazy(() => import(/* webpackChunkName: "pages/shop/shop" */ '@/components/pages/ShopViewPage/'));

const CourierMessageListPage = lazy(() => import(/* webpackChunkName: "pages/courier/messages" */ '@/components/pages/CourierMessageListPage/'));
const CourierMessageViewPage = lazy(() => import(/* webpackChunkName: "pages/courier/message" */ '@/components/pages/CourierMessageViewPage/'));

const CompanyListPage = lazy(() => import(/* webpackChunkName: "pages/company/companies" */ '@/components/pages/CompanyListPage/'));
const CompanyViewPage = lazy(() => import(/* webpackChunkName: "pages/company/company" */ '@/components/pages/CompanyViewPage/'));

// 设备管理
const CommunityListPage = lazy(() => import(/* webpackChunkName: "pages/community/communities" */ '@/components/pages/CommunityListPage/'));
const CommunityViewPage = lazy(() => import(/* webpackChunkName: "pages/community/community" */ '@/components/pages/CommunityViewPage/'));
const TrackListPage = lazy(() => import(/* webpackChunkName: "pages/community/tracks" */ '@/components/pages/TrackListPage/'));
const TrackViewPage = lazy(() => import(/* webpackChunkName: "pages/community/track" */ '@/components/pages/TrackViewPage/'));
const Track2DMapPage = lazy(() => import(/* webpackChunkName: "pages/community/2dmap" */ '@/components/pages/Track2DMapPage/'));
const Track3DMapPage = lazy(() => import(/* webpackChunkName: "pages/community/3dmap" */ '@/components/pages/Track3DMapPage/'));
const TrackCtrlPage = lazy(() => import(/* webpackChunkName: "pages/controller/device" */ '@/components/pages/TrackCtrlPage/'));
const DeviceListPage = lazy(() => import(/* webpackChunkName: "pages/community/devices" */ '@/components/pages/DeviceListPage/'));
const DeviceViewPage = lazy(() => import(/* webpackChunkName: "pages/community/device" */ '@/components/pages/DeviceViewPage/'));
const InboxListPage = lazy(() => import(/* webpackChunkName: "pages/community/inboxes" */ '@/components/pages/InboxListPage/'));
const InboxViewPage = lazy(() => import(/* webpackChunkName: "pages/community/inbox" */ '@/components/pages/InboxViewPage/'));
const CabinetListPage = lazy(() => import(/* webpackChunkName: "pages/product/cabinets" */ '@/components/pages/CabinetListPage/'));
const CabinetViewPage = lazy(() => import(/* webpackChunkName: "pages/product/cabinet" */ '@/components/pages/CabinetViewPage/'));
const CellListPage = lazy(() => import(/* webpackChunkName: "pages/product/cells" */ '@/components/pages/CellListPage/'));
const CellViewPage = lazy(() => import(/* webpackChunkName: "pages/product/cell" */ '@/components/pages/CellViewPage/'));
const ReportListPage = lazy(() => import(/* webpackChunkName: "pages/community/reports" */ '@/components/pages/ReportListPage/'));
const ReportViewPage = lazy(() => import(/* webpackChunkName: "pages/community/report" */ '@/components/pages/ReportViewPage/'));

const ProductListPage = lazy(() => import(/* webpackChunkName: "pages/product/products" */ '@/components/pages/ProductListPage/'));
const ProductViewPage = lazy(() => import(/* webpackChunkName: "pages/product/product" */ '@/components/pages/ProductViewPage/'));

// 系统管理
const ManagerListPage = lazy(() => import(/* webpackChunkName: "pages/manager/managers" */ '@/components/pages/ManagerListPage/'));
const ManagerViewPage = lazy(() => import(/* webpackChunkName: "pages/manager/manager" */ '@/components/pages/ManagerViewPage/'));
const AgentCommunityListPage = lazy(() => import(/* webpackChunkName: "pages/manager/communities" */ '@/components/pages/AgentCommunityListPage/'));
const AgentInboxListPage = lazy(() => import(/* webpackChunkName: "pages/manager/inboxes" */ '@/components/pages/AgentInboxListPage/'));
const AgentUserListPage = lazy(() => import(/* webpackChunkName: "pages/manager/users" */ '@/components/pages/AgentUserListPage/'));
const ManagerRolesPage = lazy(() => import(/* webpackChunkName: "pages/manager/roles" */ '@/components/pages/ManagerRolesPage/'));
const ManagerLogListPage = lazy(() => import(/* webpackChunkName: "pages/manager/logs" */ '@/components/pages/ManagerLogListPage/'));
const ManagerLogViewPage = lazy(() => import(/* webpackChunkName: "pages/manager/log" */ '@/components/pages/ManagerLogViewPage/'));
const VerifyCodeListPage = lazy(() => import(/* webpackChunkName: "pages/verify/codes" */ '@/components/pages/VerifyCodeListPage/'));
const BillListPage = lazy(() => import(/* webpackChunkName: "pages/accounting/bills" */ '@/components/pages/BillListPage/'));
const BillViewPage = lazy(() => import(/* webpackChunkName: "pages/accounting/bill" */ '@/components/pages/BillViewPage/'));
const SettingOptionsPage = lazy(() => import(/* webpackChunkName: "pages/setting/options" */ '@/components/pages/SettingOptionsPage/'));

// 其它
const NotFoundPage = lazy(() => import(/* webpackChunkName: "pages/404" */ '@/components/pages/NotFoundPage/'));

export default function AppAdmin() {
  return (
    <AdminLayout>
      <Suspense fallback={<Spinner size="large" />}>
        <Switch>
          <Route path="/" component={DashboardHomePage} exact />
          <Route path="/dashboard/analysis" component={DashboardAnalysisPage} exact />

          {/* 用户管理 */}
          <Route path="/user/users" component={UserListPage} exact />
          <Route path="/user/users/:id(\d+)" component={UserViewPage} exact />
          <Route path="/user/users/:id(\d+)/phones" component={UserPhoneListPage} exact />
          <Route path="/user/users/:id(\d+)/inboxes" component={UserInboxListPage} exact />
          <Route path="/user/logs" component={UserLogListPage} exact />
          <Route path="/user/logs/:id(\d+)" component={UserLogViewPage} exact />
          <Route path="/vip/orders" component={VipOrderListPage} exact />
          <Route path="/vip/orders/:id(\d+)" component={VipOrderViewPage} exact />
          <Route path="/vip/cards" component={VipCardListPage} exact />
          <Route path="/vip/cards/:id(\d+)" component={VipCardViewPage} exact />
          <Route path="/vip/coupons" component={VipCouponListPage} exact />
          <Route path="/vip/coupons/:id(\d+)" component={VipCouponViewPage} exact />
          <Route path="/umessage/messages" component={UserMessageListPage} exact />
          <Route path="/umessage/messages/:id(\d+)" component={UserMessageViewPage} exact />

          {/* 快递员管理 */}
          <Route path="/courier/couriers" component={CourierListPage} exact />
          <Route path="/courier/couriers/:id(\d+)" component={CourierViewPage} exact />
          <Route path="/courier/balances" component={BalanceListPage} exact />
          <Route path="/courier/balances/:id(\d+)" component={BalanceViewPage} exact />
          <Route path="/courier/withdraw" component={WithdrawListPage} exact />
          <Route path="/courier/withdraw/:id(\d+)" component={WithdrawViewPage} exact />
          <Route path="/courier/identities" component={IdentityListPage} exact />
          <Route path="/courier/identities/:id(\d+)" component={IdentityViewPage} exact />
          <Route path="/package/packages" component={PackageListPage} exact />
          <Route path="/package/packages/:id(\d+)" component={PackageViewPage} exact />
          <Route path="/package/posts" component={PostListPage} exact />
          <Route path="/package/posts/:id(\d+)" component={PostViewPage} exact />
          <Route path="/package/shops" component={ShopListPage} exact />
          <Route path="/package/shops/:id(\d+)" component={ShopViewPage} exact />
          <Route path="/cmessage/messages" component={CourierMessageListPage} exact />
          <Route path="/cmessage/messages/:id(\d+)" component={CourierMessageViewPage} exact />
          <Route path="/company/companies" component={CompanyListPage} exact />
          <Route path="/company/companies/:id" component={CompanyViewPage} exact />

          {/* 设备管理 */}
          <Route path="/community/communities" component={CommunityListPage} exact />
          <Route path="/community/communities/:id(\d+)" component={CommunityViewPage} exact />
          <Route path="/community/tracks" component={TrackListPage} exact />
          <Route path="/community/tracks/:id(\d+)" component={TrackViewPage} exact />
          <Route path="/community/tracks/2dmaps/:id(\d+)" component={Track2DMapPage} exact />
          <Route path="/community/tracks/3dmaps/:id(\d+)" component={Track3DMapPage} exact />
          <Route path="/community/tracks/ctrl/:id(\d+)" component={TrackCtrlPage} exact />
          <Route path="/community/devices" component={DeviceListPage} exact />
          <Route path="/community/devices/:id(\d+)" component={DeviceViewPage} exact />
          <Route path="/community/inboxes" component={InboxListPage} exact />
          <Route path="/community/inboxes/:id(\d+)" component={InboxViewPage} exact />
          <Route path="/community/cabinets" component={CabinetListPage} exact />
          <Route path="/community/cabinets/:id(\d+)" component={CabinetViewPage} exact />
          <Route path="/community/cells" component={CellListPage} exact />
          <Route path="/community/cells/:id(\d+)" component={CellViewPage} exact />

          {/* 产品管理 */}
          <Route path="/product/products" component={ProductListPage} exact />
          <Route path="/product/products/:id(\d+)" component={ProductViewPage} exact />

          {/* 故障反馈 */}
          <Route path="/report/reports" component={ReportListPage} exact />
          <Route path="/report/reports/:id(\d+)" component={ReportViewPage} exact />

          {/* 系统管理 */}
          <Route path="/manager/managers" component={ManagerListPage} exact />
          <Route path="/manager/managers/:id(\d+)" component={ManagerViewPage} exact />
          <Route path="/manager/managers/:id(\d+)/communities" component={AgentCommunityListPage} exact />
          <Route path="/manager/managers/:id(\d+)/inboxes" component={AgentInboxListPage} exact />
          <Route path="/manager/managers/:id(\d+)/users" component={AgentUserListPage} exact />
          <Route path="/manager/roles" component={ManagerRolesPage} exact />
          <Route path="/manager/logs" component={ManagerLogListPage} exact />
          <Route path="/manager/logs/:id(\d+)" component={ManagerLogViewPage} exact />
          <Route path="/verify/codes" component={VerifyCodeListPage} exact />
          <Route path="/accounting/bills" component={BillListPage} exact />
          <Route path="/accounting/bills/:id(\d+)" component={BillViewPage} exact />
          <Route path="/setting/options" component={SettingOptionsPage} exact />

          {/* 其它 */}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    </AdminLayout>
  );
}
