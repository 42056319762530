import cx from 'classnames';
import { Spin } from 'antd';

import styles from './style.less';
import useStyles from '@/hooks/useStyles';

function Spinner ({ className, ...nextProps }) {
  useStyles(styles);

  return (
    <div className={cx(styles.locals.spinner, className)}>
      <Spin {...nextProps} />
    </div>
  );
}

export default Spinner;
