import { atom, DefaultValue, selector } from 'recoil';

import parseJSON from '@/utils/parseJSON';

export const userState = atom({
  key: 'userState',
  default: parseJSON(localStorage[STORE_KEY], false),
  effects_UNSTABLE: [
    ({onSet}) => {
      onSet(newState => {
        if (newState) {
          localStorage[STORE_KEY] = JSON.stringify(newState);
        } else {
          delete localStorage[STORE_KEY];
        }
      })
    }
  ],
});

export const userItemDataState = atom({
  key: 'userItemDataState',
  default: null,
});

export const userItemShowState = atom({
  key: 'userItemShowState',
  default: false,
});

export const userItemLoadingState = atom({
  key: 'userItemLoadingState',
  default: false,
});

export const userItemState = selector({
  key: 'userItemState',
  get: ({ get }) => ({
    data: get(userItemDataState),
    show: get(userItemShowState),
    loading: get(userItemLoadingState),
  }),
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return set(newValue);
    }

    set(userItemDataState, newValue.data);
    set(userItemShowState, newValue.show);
    set(userItemLoadingState, newValue.loading);
  }
});

export const userListDataState = atom({
  key: 'userListDataState',
  default: [],
});

export const userListCountState = atom({
  key: 'userListCountState',
  default: 0,
});

export const userListLoadingState = atom({
  key: 'userListLoadingState',
  default: false,
});

export const userListQueryState = atom({
  key: 'userListQueryState',
  default: {
    page: 1,
    size: 10,
    query: {Status: ''},
  },
});

export const userListState = selector({
  key: 'userListState',
  get: ({ get }) => ({
    data: get(userListDataState),
    count: get(userListCountState),
    query: get(userListQueryState),
    loading: get(userListLoadingState),
  }),
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return set(newValue);
    }

    set(userListDataState, newValue.data);
    set(userListCountState, newValue.count);
    set(userListQueryState, newValue.query);
    set(userListLoadingState, newValue.loading);
  }
});

export const userStore = {
  item: {
    data: userItemDataState,
    show: userItemShowState,
    loading: userItemLoadingState,
  },
  list: {
    data: userListDataState,
    count: userListCountState,
    query: userListQueryState,
    loading: userListLoadingState,
  },
};
